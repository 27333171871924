import React from "react";
import { DocumentLayout } from "..";
import { DocumentEditorSidebarLayout } from "../DocumentEditorSidebarLayout/DocumentEditorSidebarLayout";

export const PageNotFoundLayout = ({message}: {message: string | undefined}) => {
  return (
    <DocumentLayout latestRevision={undefined}>
      <div className="flex flex-col justify-center space-y-8 items-center overflow-hidden h-full">
        <div>{`The page you are looking for could not be found.`}</div>
        <div>{message}</div>
      </div>
    </DocumentLayout>
  );
};
