import { Editor as TipTapEditor } from "@tiptap/react";
import { LobbyEditor } from "lobby-editor";
import { PropsWithChildren } from "react";
import { DocumentLayout } from "..";
import {
  Document,
  Maybe,
  RevisionWithReport,
  User,
} from "../../../generated/graphql";
import { Button } from "../../atoms";
import { EditingSidebarFooter } from "../../molecules";

interface DocumentEditorSidebarLayoutProps {
  documentName: string;
  editor: TipTapEditor | undefined;
  latestRevision: Maybe<RevisionWithReport> | undefined;
  setName: (name: string) => void;
  buttonText?: string;
  buttonOnClick?: () => void;
  buttonLoading?: boolean;
  document?: Document;
  contributors?: User[];
}

export const DocumentEditorSidebarLayout = ({
  documentName,
  editor,
  latestRevision,
  setName,
  children,
  buttonText,
  buttonOnClick,
  buttonLoading,
  document,
  contributors,
}: PropsWithChildren<DocumentEditorSidebarLayoutProps>) => {
  return (
    <DocumentLayout documentName={documentName} latestRevision={latestRevision}>
      <div className="flex flex-row justify-center flex-grow w-full">
        {/* <div className="flex flex-col flex-grow "> */}
        <div className="flex flex-col justify-start flex-grow overflow-scroll ">
          <div className="flex shadow">
            <input
              className="flex flex-grow p-2 m-2 mx-10 text-4xl font-semibold text-gray-800 placeholder-gray-400 focus:bg-gray-100 focus:outline-none rounded-xl hover:bg-gray-100 outline-0"
              value={documentName}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="Document title..."
            />
          </div>
          <div className="px-10 pt-4 overflow-scroll ">
            <LobbyEditor editor={editor} editable={true} />
          </div>
        </div>
        {/* </div> */}
        <div className="flex flex-col justify-between pb-10 h-full max-w-xs min-w-[15rem] px-5 bg-gray-50">
          <div className="flex flex-col flex-grow">{children}</div>
          {!buttonText ? (
            <div className="flex-col text-[10px]">
              <EditingSidebarFooter
                latestRevision={latestRevision}
                document={document}
                contributors={contributors}
              />
            </div>
          ) : (
            <Button
              text={"Create new document"}
              onClick={buttonOnClick}
              loading={buttonLoading}
            />
          )}
        </div>
      </div>
    </DocumentLayout>
  );
};
