import React from "react";

export const UnauthorizedLayout = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen overflow-hidden">
      <div className="font-semibold">
        You are unauthorized to access this page.
      </div>
    </div>
  );
};
