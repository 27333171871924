import { useQuery } from "@apollo/client";
import { ExclamationIcon } from "@heroicons/react/outline";
import { PropsWithChildren } from "react";
import {
  BaseLayout,
  DocumentLayout,
  LoadingLayout,
  UnauthorizedLayout,
} from "..";
import { useAuthenticationContext } from "../../../contexts/AuthenticationContext";
import {
  Community,
  QueryCommunityArgs,
  User,
} from "../../../generated/graphql";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";
import { CommunityQuery } from "../../../lib/queries/Communities";
import { MeQuery } from "../../../lib/queries/Users";
import { BreadCrumbs } from "../../atoms";
import { ModeratorSidebar, ModeratorSidebarList } from "../../molecules";

interface ModeratorLayoutProps {}

export const ModeratorLayout = ({
  children,
}: PropsWithChildren<ModeratorLayoutProps>) => {
  const { token } = useAuthenticationContext();

  const { communitySlug, moderatorName: pathName } = useLobbyRouter();
  let moderatorName = "Moderator",
    moderatorIcon = <></>;

  const { data: communityData, loading: communityLoading } = useQuery<
    { community: Community },
    QueryCommunityArgs
  >(CommunityQuery, { variables: { communitySlug } });
  const { data: userData, loading: userLoading } = useQuery<{ me: User }>(
    MeQuery
  );

  const moderatorItems = ModeratorSidebarList.filter((item) => {
    return item.name === pathName;
  });

  if (moderatorItems.length > 0) {
    moderatorName = moderatorItems[0].name;
    moderatorIcon = moderatorItems[0].icon;
  }

  if (communityLoading || userLoading) return <LoadingLayout />;
  else if (
    token &&
    (communityData?.community?.isModerator || userData?.me?.isSuperAdmin)
  )
    return (
      <DocumentLayout latestRevision={undefined} showLanguageSelector={false}>
        <div className="flex flex-col items-center overflow-scroll">
          <div className="flex flex-row items-center justify-center w-screen p-1 text-sm text-yellow-800 bg-yellow-300 sticky-top ">
            <ExclamationIcon className="w-4 h-4" />
            <p>
              You&apos;re in
              <span className="font-semibold "> moderator mode</span>.
            </p>
          </div>
          <div className="flex flex-row justify-center w-screen max-w-5xl px-16 space-x-6 pt-9">
            <div className="h-full">
              <ModeratorSidebar />
            </div>
            <div className="flex flex-col flex-grow">
              <BreadCrumbs addPaddingX={false} />
              <div className="flex flex-row items-center space-x-3">
                {moderatorName !== "Moderator" && (
                  <div className="w-10 h-10">{moderatorIcon}</div>
                )}
                <h1 className="text-3xl font-bold ">{moderatorName}</h1>
              </div>
              {children}
            </div>
          </div>
        </div>
      </DocumentLayout>
    );
  else return <UnauthorizedLayout />;
};
