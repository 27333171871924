/* eslint-disable @next/next/no-img-element */
import { useQuery } from "@apollo/client";
import Image from "next/image";
import tokenImage from "../../../../public/assets/tokenImage/tokenImage.png";
import { PropsWithChildren, useEffect, useState } from "react";
import { useAuthenticationContext } from "../../../contexts/AuthenticationContext";
import { Role } from "../../../generated/graphql";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";
import { DocumentRolesQuery } from "../../../lib/queries/Documents";
import { RevealAddress } from "../../atoms";
import { DocumentLayout } from "../DocumentLayout/DocumentLayout";
import { LoadingLayout } from "../LoadingLayout/LoadingLayout";
import { trimEllip } from "../../../lib/util/util";

interface DocumentAccessUnauthorizedProps {}

export const DocumentAccessUnauthorized = ({
  children,
}: PropsWithChildren<DocumentAccessUnauthorizedProps>) => {
  const { communitySlug, documentSlug } = useLobbyRouter();
  const [rolesLength, setRolesLength] = useState<number | undefined>(0);
  const { user } = useAuthenticationContext();
  const {
    data: documentRoles,
    loading,
    error,
  } = useQuery<{ requiredDocumentRoles: Role[][] }>(DocumentRolesQuery, {
    variables: { communitySlug: communitySlug, slug: documentSlug },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    setRolesLength(documentRoles?.requiredDocumentRoles.length);
  }, [documentRoles?.requiredDocumentRoles.length, setRolesLength]);

  if (loading) return <LoadingLayout />;

  return (
    <DocumentLayout latestRevision={undefined}>
      <div className="flex flex-col mt-4 overflow-y-scroll">
        <div className="flex flex-col justify-center items-center border-2 border-gray-200 rounded-lg py-3 px-32 mb-4 overflow-x-scroll">
          <div className="font-bold text-gray-400 text-6xl">403</div>
          <div className="font-bold">Access Denied</div>
          <div className="grid grid-rows-2 place-items-center my-4">
            <div>
              <RevealAddress address={user?.address} />
            </div>
            <div>
              To see this document, you must have the following in your wallet:
            </div>
          </div>
          <div>
            {documentRoles?.requiredDocumentRoles?.map((roles, index) => {
              return (
                <div key={index} className="grid place-items-center">
                  <div className="flex my-1 justify-center items-center">
                    {roles.map((role, index) => {
                      return (
                        <div
                          className="flex flex-row place-items-center"
                          key={index}
                        >
                          {index > 0 ? (
                            <div className="font-bold text-gray-600 px-2">
                              OR
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="flex flex-row place-items-center border py-2 px-4 bg-gray-200 rounded-lg space-x-2  ">
                            <div className="">
                              {role.token?.imageUrl === "" ? (
                                <Image
                                  src={tokenImage}
                                  alt="Default Token"
                                  className="h-auto w-auto"
                                />
                              ) : (
                                <img
                                  src={role.token?.imageUrl}
                                  alt="Token"
                                  className="h-14 w-14"
                                />
                              )}
                            </div>
                            <div className="font-bold text-lg text-gray-400">
                              {trimEllip(role.threshold, 8)}
                            </div>
                            <div className="font-bold text-lg text-gray-400">
                              {role.token?.name}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {roles.length > 0 && index < (rolesLength as number) - 1 ? (
                    <div className="font-bold text-gray-600">AND</div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </DocumentLayout>
  );
};
