import React from "react";
import { Spinner } from "../../atoms";

export const LoadingLayout = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen overflow-hidden">
      <Spinner />
    </div>
  );
};
